(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("ReactDOM"));
	else if(typeof define === 'function' && define.amd)
		define("@draftkings/dk-pre-packs", ["React", "ReactDOM"], factory);
	else if(typeof exports === 'object')
		exports["@draftkings/dk-pre-packs"] = factory(require("React"), require("ReactDOM"));
	else
		root["@draftkings/dk-pre-packs"] = factory(root["React"], root["ReactDOM"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__804__, __WEBPACK_EXTERNAL_MODULE__196__) {
return 